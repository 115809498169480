<template>
  <div v-if="show" class="container">
    <div class="main-card">
      <div class="form-content">
        <div class="heading">
          <img src="/assets/img/Blits-logo-black.png" />
          <h4>The chatbot ecosystem</h4>
        </div>
        <div>
          <b-modal
            id="modal1"
            v-model="mobile"
            centered
            ok-only
            title="Mobile Device Detected"
          >
            <p class="my-4">
              Blits is optimised for large-screen use only. Most of the
              functions will not work on a mobile device. Please login via a
              desktop or a laptop to use the Blits platform.
            </p>
          </b-modal>
        </div>
        <form class="form" @submit.prevent>
          <div class="login">
            <div
              class="button-group button-group-microsoft"
              v-if="!showLoginForm"
            >
              <h4>
                Login via a connected
                <Tooltip
                  style="padding-bottom: 1px"
                  :title="`If your Microsoft account is connected to a Blits account you can login via this button. If you have trouble signing in, please try an incognito browser or check out this help <a style='color: black; font-weight: bold;' href='/user-manual/support/faq'>page</a>.`"
                ></Tooltip>
              </h4>
              <button
                class="btn btn--purple gradient login-button"
                @click="$adal.login()"
              >
                <i class="icon icon-microsoft"></i>Microsoft account
              </button>
            </div>
            <div
              class="input-container email-input-container"
              v-if="showLoginForm"
            >
              <div class="icon-box"><i class="icon icon-user"></i></div>
              <input
                class="input-field"
                type="text"
                v-model="userId"
                placeholder="Email"
              />
            </div>
            <div class="input-container" v-if="showLoginForm">
              <div class="icon-box"><i class="icon icon-password"></i></div>
              <input
                class="input-field"
                id="password"
                :type="passInputType"
                v-model="password"
                placeholder="Password"
              />
              <i
                class="icon-eye"
                id="togglePassword"
                v-on:mouseup="toggleShowPassword()"
                v-on:mousedown="toggleShowPassword()"
              ></i>
            </div>
            <h4
              class="switch-screen-link"
              @click="showLoginForm = !showLoginForm"
            >
              {{
                showLoginForm
                  ? "or login via microsoft account"
                  : "or login via email"
              }}
            </h4>
            <h4 v-if="showLoginForm">
              <a class="forgot-password" href="/forgot-password"
                >Forgot password?</a
              >
            </h4>
            <div class="button-group" v-if="showLoginForm">
              <button
                v-if="!loginInProgress"
                class="btn btn--purple gradient login-button"
                @click="login()"
              >
                <i class="icon icon-blits-logo-white"></i>Login with email
              </button>
              <SmallLoading v-else class="loading"></SmallLoading><br />
            </div>
          </div>
        </form>
        <h4 class="signup-link">No account, signup <a href="/signup">here</a></h4>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import authService from "../../services/auth.service";
import popupService from "../../services/popup.service";
import authManagmentMixin from "./auth-managment.mixin";
import onLoggedInMixin from '../_mixins/on-logged-in.mixin';
import SmallLoading from "../helpers/SmallLoading.vue";
import Tooltip from "../helpers/Tooltip.vue";

@Component({ components: { SmallLoading, Tooltip } })
export default class LogIn extends Mixins(authManagmentMixin, onLoggedInMixin) {
  show = false;
  private mobile = false;

  private userId = "";
  private password = "";
  private loginInProgress = false;

  private passInputType = "password";
  showLoginForm = false;

  async beforeMount() {
    await this.isAuthenticated();
    this.show = true;
    this.detectmob();
    this.routerCheck();
  }

  async login() {
    this.loginInProgress = true;
    let loginSuccess = false;
    try {
      loginSuccess = await authService.login(this.userId, this.password);
    } catch (e) {
      this.loginInProgress = false;
      popupService.showError("Login error", e.message);
    } finally {
      if (loginSuccess) {
        this.moveToBots();
        this.removeBG();
        this.loginInProgress = false;
        this.onLoggedIn();
      }
    }
  }

  removeBG() {
    document.body.classList.remove("image-background");
  }

  detectmob() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.mobile = true;
    }
  }

  toggleShowPassword() {
    this.passInputType =
      this.passInputType === "password" ? "text" : "password";
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./auth";

.heading {
  margin-bottom: 10px;
}

.container .main-card {
  min-height: 545px;
}

.form-content {
  background-color: transparent;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
