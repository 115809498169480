import FlowComponent from '../FlowComponent'
import { IBlock } from '../../../../../../../common/blocks/block.interface'

export default class ConditionalComponent<T extends IBlock> extends FlowComponent<T> {
  constructor (element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)
  }

  updateRestrictions () {
    return null
  }

  changeOperation (operation: number) {
    const customData: any = this.getCustomData
    customData.operation = operation
  }
}
