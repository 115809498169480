<template>
  <div v-if="current" class="form--wrapper">
    <section id="common">
      <div class="page" v-if="page === 1">
        <h4>Please choose a LLM provider from the options below</h4>
        <div class="description">
          Here, you can select your preferred Large Language Model (LLM) provider to get started with building and deploying your own custom language model.
        </div>
        <div class="selector-wrapper">
          <div v-for="(provider, index) in providers">
            <div v-if="provider.enabled" class="provider" :class="provider.selected ? 'selected' : ''" @click="selectProvider(provider)">
              <div class="name">{{ provider.name }}</div>
              <img class="image" :src="provider.icon" alt="provider.name" />
              <div class="description">{{ provider.description }}</div>
            </div>

            <div v-else class="provider disabled">
              <div class="name">{{ provider.name }}</div>
              <img class="image" :src="provider.icon" alt="provider.name" />
              <div class="description">Following soon as part of our ecosystem</div>
            </div>
          </div>
        </div>
      </div>

      <div class="page" v-if="page === 2">
        <LLMdetails :newLLM="current" :variables="variables" />
      </div>

      <div class="page" v-if="page === 3">
        <LLMDocuments :current="current" :variables="variables" />
      </div>

      <footer class="modal-footer-button">
        <button v-if="page !== 3" class="btn btn--purple gradient" @click="page = page + 1" type="button">
          Next step
          <i class="icon icon-arrow-right"></i>
        </button>

        <button v-if="page === 3" class="btn btn--purple gradient" :disabled="disabled" @click="createLLM" type="button">
          Create LLM
          <i class="icon icon-arrow-right"></i>
        </button>

        <button v-if="page !== 1" class="btn btn--white blue" style="margin-right: 20px" @click="page = page - 1" type="button">Back</button>
      </footer>
    </section>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { LlmProviderEnum } from '../../../../../common/enums/bot/llm/llm-provider.enum'
import Autocomplete from '@/components/helpers/Autocomplete.vue'
import UploadFileExtension from '@/components/admin/flow-editor/block-config/block-types/task-block/block-extensions/UploadFileExtension.vue'
import { NewLLM } from '../../../../../common/types/llm-type'
import LLMdetails from './components/LLMdetails.vue'
import LLMDocuments from './components/LLMdocuments.vue'

@Component({
  components: { UploadFileExtension, Autocomplete, LLMdetails, LLMDocuments }
})
export default class newLLM extends Vue {
  @Prop() variables
  @Prop() selectedFilter

  page = 1
  disabled = false

  current: NewLLM = {
    name: '',
    description: '',
    model: '',
    isComplete: false,
    provider: LlmProviderEnum.OpenAI,
    documents: []
  }

  providers = [
    {
      name: 'OpenAI',
      enum: LlmProviderEnum.OpenAI,
      icon: '/assets/icons/openai-logo.svg',
      subtext: 'Worlds best GPT models',
      description: 'Renowned for its advanced AI models and comprehensive API support.',
      selected: true,
      enabled: true
    },
    {
      name: 'Anthropic',
      enum: LlmProviderEnum.Anthropic,
      icon: '/assets/icons/anthropic-logo.svg',
      subtext: 'Worlds best GPT models',
      description: 'Antropic Claude LLM focuses on creating interpretable and steerable AI systems, ensuring high levels of safety and reliability.',
      selected: false,
      enabled: false
    },
    {
      name: 'Google GenAI',
      enum: LlmProviderEnum.GoogleGenAI,
      icon: '/assets/icons/googleai-logo.svg',
      subtext: 'Worlds best GPT models',
      description: 'Offers powerful AI tools backed by Google’s extensive resources and research in artificial intelligence.',
      selected: false,
      enabled: false
    },
    {
      name: 'Llama',
      enum: LlmProviderEnum.Llama,
      icon: '/assets/icons/meta-logo.svg',
      subtext: 'Worlds best GPT models',
      description: '',
      selected: false,
      enabled: false
    },
    {
      name: 'Mistral',
      enum: LlmProviderEnum.Mistral,
      icon: '/assets/icons/mistral-logo.png',
      subtext: 'Worlds best GPT models',
      description: '',
      selected: false,
      enabled: false
    },
    {
      name: 'Hugging Face',
      enum: LlmProviderEnum.HuggingFace,
      icon: '/assets/icons/huggingface-logo.svg',
      subtext: 'Worlds best GPT models',
      description: 'A popular choice for community-driven model development with a vast collection of pre-trained models and easy integration options.',
      selected: false,
      enabled: false
    }
  ]

  newData(_) {
    this.page = 2
    this.disabled = false
  }

  get LlmProviderEnum() {
    return LlmProviderEnum
  }

  selectProvider(provider) {
    for (let prof of this.providers) {
      prof.selected = false
    }
    provider.selected = true
    this.current.provider = provider.enum
  }

  createLLM() {
    this.disabled = true
    this.$emit('createLLM', this.current)

    setTimeout(() => {
      this.disabled = false
    }, 5000)
  }
}
</script>

<style scoped lang="scss">
@import '../../../assets/scss/variables';

.empty {
  font-size: 14px;
  color: $grey;
  margin-top: 20px;
}

.page {
  min-height: 400px;
}

.selector-wrapper {
  min-height: 200px;
  width: calc(100% - 20px);
  margin: 20px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;

  .provider {
    background-color: $blue-background;
    width: 180px;
    height: 210px;
    border-radius: 12px;
    border: 1px solid $grey;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 10px;

    .name {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
    }

    .description {
      font-size: 10px;
      text-align: center;
    }

    &.disabled {
      background-color: #d0d0d0;
    }

    &.selected {
      background-color: $main;
      border: 0px solid $main;

      .name {
        color: $white;
      }

      .description {
        color: $white;
      }
    }

    .image {
      height: 50px;
      width: 50px;
      overflow: hidden;
      margin-bottom: 10px;
    }
  }
}
</style>
