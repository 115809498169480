<template>
  <div class="form--wrapper">
    <div class="bot">
      <Loading title="Loading your LLM's data" v-if="loading" />
    </div>
    <div class="container" v-if="llm">
      <div class="container--content">
        <div class="bot-title-container">
          <div class="bot-title">{{ llm.llmDisplayName }}</div>
        </div>

        <div class="content-header">
          <div class="row">
            <div class="col-2">
              <router-link :to="'/llms'" class="btn btn--white"> <i class="icon icon-back"></i>To all LLMs </router-link>
            </div>
            <div class="x">
              <NavTabs
                v-model="currentTab"
                :tabs="['settings', 'documents', 'context', 'guardrails', 'analytics', 'test']"
                :hideDialogButton="true"
                :currentTab="currentTab"
              />
            </div>
          </div>
        </div>

        <div v-show="!loading">
          <div class="box-wrapper">
            <div class="setting" v-if="currentTab === 'settings'">
              <LLMdetails :LLM="llm" :variables="variables" :errors="errors" />
            </div>

            <div class="documents" v-if="currentTab === 'documents'">
              <LLMDocuments :current="llm" :variables="variables" :errors="errors" />
            </div>

            <div class="context" v-if="currentTab === 'context'">
              <div class="context-wrapper" v-if="llm.context">
                <div class="title">This LLM is trained on the following</div>
                <div class="context">{{ llm.context }}</div>
              </div>

              <!-- <div class="context-wrapper" v-if="!llm.context">
                <div class="title">This LLM doesn't have context yet, re-train the LLM to retrieve the context</div>
              </div> -->
            </div>

            <div class="guardrails" v-if="currentTab === 'guardrails'">guardrails will come here {{ llm.guardrails }}</div>

            <div class="analytics" v-if="currentTab === 'analytics'">analytics will come here</div>

            <div class="test" v-if="currentTab === 'test'">
              <div class="test-wrapper">
                <div class="row" :class="(i & 1) === 0 ? 'even' : ''" v-for="(repsonse, i) in testResponses">
                  <span class="response" :class="(i & 1) === 0 ? 'even' : ''">
                    {{ repsonse }}
                  </span>
                </div>
              </div>
            </div>

            <div v-if="currentTab !== 'test'" class="footer-button-wrapper">
              <button class="btn btn--purple gradient" :disabled="loading" @click="trainLLMButton()" type="button">
                Train LLM
                <i class="icon icon-paperplane"></i>
              </button>
              <button class="btn btn--purple gradient" :disabled="loading" @click="saveLLMButton()" type="button">
                Save
                <i class="icon icon-arrow-right"></i>
              </button>
            </div>
            <div v-if="currentTab === 'test'" class="footer-test-wrapper">
              <input v-model="testText" class="test-input" type="text" placeholder="Test your LLM with typing a message here" />
              <button class="btn btn--purple gradient" :disabled="loading" @click="testLLMButton" type="button">
                Send
                <i class="icon icon-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { LLMObject } from '../../../../../common/types/llm-type'
import LlmManagementMixin from '../../../components/admin/bots/_mixins/llm-management.mixin'
import popupService from '../../../services/popup.service'
import NavTabs from '../../../components/admin/bots/nav-tabs/NavTabs.vue'
import LLMdetails from './components/LLMdetails.vue'
import LLMDocuments from './components/LLMdocuments.vue'
import Loading from '../../../components/helpers/Loading.vue'

@Component({ components: { NavTabs, LLMdetails, LLMDocuments, Loading } })
export default class LLMOverview extends Mixins(LlmManagementMixin) {
  llm: LLMObject | undefined | any = undefined

  @Prop() variables
  errors = {
    name: '',
    description: ''
  }

  loading: boolean = true
  currentTab: string = 'settings'
  testText: string = ''
  testResponses: string[] = []

  mounted() {
    const llmId = this.$router.history.current.params.llmid
    this.loadLLM(llmId)
  }

  async loadLLM(llmId: string) {
    this.loading = true
    let response: any = {}
    try {
      response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/llm/${llmId}`)
    } catch (error) {
      if (error.code === 'permission_error') {
        this.$router.push('/llms')
        popupService.showWarning('You do not have permission')
      }
    }
    this.llm = response.data
    this.loading = false
  }

  async testLLMButton() {
    this.testResponses.push(this.testText)
    if (!this.llm) return
    if (!this.testText) return
    const result = await this.runLLM(this.llm, this.testText)
    this.testText = ''
    this.testResponses.push(result)
  }

  async saveLLMButton() {
    this.loading = true
    await this.updateLLM(this.llm)
    popupService.showInfo('Done saving your updates')
    this.loading = false
  }

  async trainLLMButton() {
    this.trainLLM(this.llm)
  }
}
</script>

<style lang="scss">
@import '../../../assets/scss/variables';

.content-header {
  .row {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;

    &.even {
      justify-content: flex-end;
    }

    .response {
      background-color: $light-blue;
      border-radius: 10px;
      color: $default-dark;
      padding: 15px 20px;
      font-size: 14px;

      &.even {
        background-color: $default-dark;
        color: $white;
      }
    }
  }
}

.context-wrapper {
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .context {
    font-size: 14px;
    color: $default-dark;
  }
}

.box-wrapper {
  background-color: $white;
  border-radius: 10px;
  -webkit-box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  position: relative;
  width: 100%;
  max-width: 1024px;
  //   margin: 0 auto;
  height: 70vh;
  max-height: calc(100vh - 217px);
  padding: 30px 30px 110px 30px;

  .footer-button-wrapper {
    position: absolute;
    bottom: 30px;
    right: 35px;
  }

  .test-wrapper {
    height: calc(70vh - 140px);

    .row {
      margin-left: 0px;
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;

      &.even {
        justify-content: flex-end;
      }

      .response {
        width: auto;
        background-color: $light-blue;
        border-radius: 10px;
        color: $default-dark;
        padding: 15px 20px;
        font-size: 14px;

        &.even {
          background-color: $default-dark;
          color: $white;
        }
      }
    }
  }

  .footer-test-wrapper {
    position: absolute;
    bottom: 15px;
    border-radius: 0px 0px 10px 10px;
    height: 80px;
    width: calc(100% - 30px);
    margin-left: -15px;

    .test-input {
      height: 100%;
      border-color: $main;
      border-radius: 10px;
      margin-top: 13px;
      padding: 26px 140px 26px 30px !important;
    }

    .btn {
      position: absolute;
      bottom: 15px;
      right: 20px;
    }
  }
}
</style>
