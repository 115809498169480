import DialogBlockComponent from '../DialogBlockComponent'
import flowComponentService from '../../../flow-component.service'
import { Restrictions } from '../../../../../../../../../common/blocks/restrictions'
import { IDictionaryBlock } from '../../../../../../../../../common/blocks/dialog/dictionary-block.interface'

export default class DictionaryBlockComponent<T extends IDictionaryBlock> extends DialogBlockComponent<T> {
  constructor (element: any, gfx: any, functionSettings: any = null, customData: any) {
    super(element, gfx, functionSettings, customData)
  }

  updateRestrictions () {
    this.updateSizeCapability()
    flowComponentService.updateRestrictions(this.id, this.customData)
  }

  addNewData () {
    const customData: any = this.getCustomData
    customData.dictionaryElements.push({
      key: '',
      value: ''
    })
  }

  private updateSizeCapability () {
    const restrictions = Restrictions.channelResctrictions
    if (restrictions.allBlocks) {
      for (const channel of Object.keys(restrictions.allBlocks)) {
        for (const restriction of restrictions.allBlocks[channel]) {
          const possibleAnswers: {key: string; value: string}[] = this.customData.dictionaryElements ? this.customData.dictionaryElements : []

          for (const answer of possibleAnswers) {
            if (Number(restriction.condition) <= answer.key.length || Number(restriction.condition) <= answer.value.length) {
              flowComponentService.remotelyAddRestriction(this.id, this.customData.fc, channel, restriction.type, restriction.message)
            } else {
              flowComponentService.remotelyDeleteRestriction(this.id, this.customData.fc, channel, restriction.type, restriction.message)
            }
          }
        }
      }
    }
  }
}
