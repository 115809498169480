<template>
  <div class="container">
    <div class="main-card sigup">
      <div class="form-content">
        <div class="signup-wrapper">
          <div class="form-wrapper">
            <div class="signup-left">
              <div class="heading">
                <img class="logo" src="/assets/img/Blits-logo-black.png" /><br />
                <h5>
                  Get instant access to a <br />
                  huge ecosystem of chat &#38; <br />
                  voicebot technology
                </h5>
                <p>Start your digital transformation journey today</p>
                <img class="box-img" src="/assets/img/blits-box-img.png" />
                <!-- <p class="subheader-maintain"> <b>Connect. Build. Deploy. Maintain.</b></p> -->
              </div>
            </div>
            <div class="signup-right">
              <div v-if="signupSuccess" class="success">
                <div class="heading">
                  <h6>Account created</h6>
                  <br />
                  <img class="success-icon" src="/assets/img/signup-success-icon.png" /><br />
                  <p>Check your email to complete the process</p>
                </div>
              </div>
              <div v-else class="signup-fields">
                <div class="content">
                  <h6 v-if="!activation_email && !this.data.customPlanName">Sign up for a 14 <br />day Trial</h6>
                  <h6 v-else-if="this.title">{{ this.title }}</h6>
                  <h6 v-else>Complete your AppSumo signup</h6>
                  <div v-if="!ssoCreation">
                    <p class="subheader-building">
                      Start building within 5 min
                    </p>
                  </div>

                  <label for="name">Your Name</label><br />
                  <input v-model="data.name" type="text" id="name" class="form-control"
                    placeholder="Dean Kitman" /><br />
                  <div v-show="nameError" class="invalid">
                    Please enter a valid name
                  </div>

                  <label for="organization">Organization Name</label><br />
                  <input v-model="data.organization" type="text" id="organization" class="form-control"
                    placeholder="Movers Inc." /><br />
                  <div v-show="organizationError" class="invalid">
                    Please enter a valid organization name
                  </div>

                  <label for="work-email">{{ createAccountEmailText }}</label><br />
                  <input v-model="data.workEmail" type="email" id="work-email" class="form-control"
                    placeholder="D.Kitman@movers.inc" :disabled="activation_email" /><br />
                  <div v-show="emailError" class="invalid">
                    Please enter a valid email
                  </div>

                  <div v-if="!ssoCreation">
                    <label for="password">Password</label><br />
                    <input v-model="data.password" type="password" id="password" class="form-control"
                      placeholder="Pick a strong password" /><br />
                    <div v-show="passwordError" class="invalid">
                      Password must at least be 8 characters long
                    </div>
                  </div>

                  <div v-if="!ssoCreation">
                    <label for="confirm-password">Re-enter Password</label><br />
                    <input v-model="data.confirmPassword" type="password" id="confirm-password" class="form-control"
                      placeholder="Enter the same password" /><br />
                    <div v-show="confirmPasswordError" class="invalid">
                      The passwords do not match
                    </div>
                  </div>

                  <SmallLoading v-if="isCreating" class="loading-manual"></SmallLoading>
                  <button class="btn btn--purple gradient submit-button" @click="submit(ssoCreation ? identity : null)"
                    v-else>
                    <i :class="createAccountIcon" v-if="ssoCreation"></i>{{ createAccountButton }}
                  </button>

                  <div v-if="!ssoCreation" class="lower-signup-right-wrapper">
                    <div class="divider">
                      <span>or</span>
                    </div>
                    <p class="small-text">signup using an existing</p>
                    <button class="microsoft-button" @click="signUpWithMicrosoft()">
                      <i class="icon icon-microsoft"></i>
                      Microsoft Acount
                    </button>
                  </div>
                  <h4 class="signup-link">Already have an account, login <a href="/login">here</a></h4>
                </div>
              </div>
              <p class="terms">
                By signing up, you accept the
                <a href="https://www.blits.ai/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Blits
                  Terms of Service</a>
                and <br />
                acknowledge the
                <a href="https://www.blits.ai/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy
                  Policy</a>.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import Select from "../helpers/Select.vue";
import SmallLoading from "../helpers/SmallLoading.vue";
import { countryNamesByCode } from "../../../../common/constants/countries.constant";
import usersService from "../../services/users.service";
import UserSignUpDto from "../../../../common/interfaces/user-signup-dto.interface";
import popupService from "../../services/popup.service";
import {
  UserIdentity,
  USER_IDENTITY_PLATFORM,
} from "../../../../common/types/users/user-identity.type";
import authManagmentMixin from "./auth-managment.mixin";

@Component({
  components: {
    Select,
    SmallLoading,
  },
})
export default class SignUp extends Mixins(authManagmentMixin) {
  private signupSuccess = false;

  private organizationRegex =
    /^([a-zA-Z0-9\.\-\\_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]\s\']){3,}$/;

  private nameError = false;
  private emailError = false;
  private organizationError = false;
  private passwordError = false;
  private confirmPasswordError = false;
  private ssoCreation = false;
  private isCreating = false;
  private createAccountHeading = "Sign up for a 14 day Trial";
  private createAccountButton = "Create Account";
  private createAccountEmailText = "Work email";
  private createAccountIcon = "";
  private identity = "";

  private data: UserSignUpDto = {
    identity: USER_IDENTITY_PLATFORM,
    name: "",
    organization: "",
    workEmail: "",
    country: "",
    password: "",
    confirmPassword: "",
    appsumoPlanId: "",
    appsumoUuid: "",
    appsumoInvoiceItemUuid: "",
    customPlanName: "",
    couponId: ""
  };

  activation_email = "";
  title = ""

  async beforeMount() {
    await this.isAuthenticated();
    this.routerCheck();
  }

  async created() {
    this.activation_email = this.$route.params.activation_email;
    this.title = this.$route.params.title;

    this.data.workEmail = this.activation_email;
    this.data.appsumoPlanId = this.$route.params.plan_id;
    this.data.appsumoUuid = this.$route.params.uuid;
    this.data.appsumoInvoiceItemUuid = this.$route.params.invoice_item_uuid;
    this.data.customPlanName = this.$route.params.prod_name;
    this.data.couponId = this.$route.params.coupon_id;

    this.data.country =
      countryNamesByCode[(await usersService.findUserLocation()).country];
  }

  private async submit(identity?: UserIdentity) {
    try {
      identity ? (this.data.identity = identity) : undefined;
      const isIdentityPlatform = this.data.identity == USER_IDENTITY_PLATFORM;
      this.validateFields();
      const error =
        this.nameError ||
        this.emailError ||
        this.organizationError ||
        (isIdentityPlatform
          ? this.passwordError || this.confirmPasswordError
          : false);
      if (!error) {
        this.isCreating = true;
        const res = await usersService.signUp(this.data);
        if (res.status == 200 && isIdentityPlatform) {
          this.signupSuccess = true;
          this.isCreating = false;
        } else {
          window.open(res.data, "_self");
        }
      }
    } catch (e) {
      popupService.showError("Error occured while signing up", e.message);
      this.isCreating = false;
    }
  }

  private signUpWithMicrosoft() {
    this.ssoCreation = true;
    this.createAccountHeading = "Sign up with your Microsoft Account";
    this.createAccountButton = "Sign up with Microsoft";
    this.identity = "microsoft";
    this.createAccountIcon = "icon icon-microsoft";
    this.createAccountEmailText = "Microsoft account email";
  }

  private validateFields() {
    this.validateNameField();
    this.validateOrganizationField();
    this.validateEmailField();
    this.validatePasswordField();
    this.validateConfirmPasswordField();
  }

  private validateNameField() {
    this.nameError = this.data.name.trim() == '';
  }

  private validateOrganizationField() {
    this.organizationError = !this.organizationRegex.test(
      this.data.organization
    );
  }

  private validateEmailField() {
    this.emailError = !this.matchEmail(this.data.workEmail);
  }

  private validatePasswordField() {
    this.passwordError = this.data.password.trim().length < 8;
  }

  private validateConfirmPasswordField() {
    this.confirmPasswordError = this.data.confirmPassword != this.data.password;
  }
}
</script>

<style lang="scss" scoped>
@import "./auth";

.form-content {
  background-color: white;
}

.container .main-card {
  min-height: 600px;
}

@media only screen and (max-width: 770px) {
  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .signup-left {
      margin: 0 auto;
      width: 100%;
    }
  }
}
</style>
