<template>
  <div v-if="isUserAuthenticated" class="nav">
    <div class="nav-container">
      <div class="logo">
        <router-link :to="'/bots'">
          <img src="/assets/img/Blits-icon-color.png" />
          <img class="blits-name" src="/assets/img/Blits-logo-text-white.png" />
        </router-link>
      </div>
      <div class="nav-container-top">
        <div class="items">
          <router-link :to="'/bots'">
            <i class="icon icon-dialog-manager"><div class="nav-title">Bots</div></i>
          </router-link>
        </div>
        <div class="items" v-if="userInfo.canSeeLLMs">
          <router-link :to="'/llms'">
            <i class="icon icon-llm"> <div class="nav-title">LLMs</div></i>
          </router-link>
        </div>
        <div class="items">
          <a href="https://support.blits.ai/portal/en/kb/knowledgebase" target="_blank">
            <i class="icon icon-book"> <div class="nav-title">Guide</div></i>
          </a>
        </div>
        <div class="items" v-if="userInfo.canSeeAdminPage">
          <router-link :to="'/configuration'">
            <i class="icon icon-cog"> <div class="nav-title">Admin</div></i>
          </router-link>
        </div>
        <div class="items" v-if="userInfo.isMasterTenantAdmin">
          <router-link :to="'/master-configuration'" class="centered">
            <div>
              <i class="icon icon-cog"></i>
              <div class="nav-title">Master Admin</div>
            </div>
          </router-link>
        </div>
        <div id="hire" class="items">
          <button class="hire" ref="open-hire-button" @click="toggleHire">
            <i class="icon icon-expert"> <div class="nav-title">Hire</div></i>
          </button>
          <div class="hover-box-hire" v-if="hire" v-click-outside="{ exclude: ['open-hire-button'], handler: 'toggleHire' }">
            <div class="hover-items">
              <div class="header">Hire an expert for your conversation design</div>
              <div class="subheader">Get matched with a professional, so you can:</div>
              <div class="container-hire">
                <i class="icon-ok"></i>
                <div class="text">Stand out with your conversations</div>
              </div>
              <div class="container-hire">
                <i class="icon-ok"></i>
                <div class="text">Create tailored experiences</div>
              </div>
              <div class="container-hire">
                <i class="icon-ok"></i>
                <div class="text">Learn to use advance Blits features</div>
              </div>
              <div class="container-hire">
                <i class="icon-ok"></i>
                <div class="text">Grow your business with unique customer experiences</div>
              </div>
              <img class="hire-image" src="/assets/img/Blits-match-img.png" />
            </div>
            <div class="hire-button">
              <button class="btn btn--purple gradient" @click="getMatched">Get Matched</button>
            </div>
          </div>
        </div>
      </div>
      <div class="links">
        <div class="nav-container-bottom">
          <div
            id="profile"
            class="items"
            @mouseover="upHere = true"
            @mouseleave="
              upHere = false
              tenantSelector = false
            "
          >
            <div class="user-photo">
              <img id="avatar" src="/assets/img/user-image.png" />
              <div class="hover-box" v-if="upHere" @mouseleave="upHere = false">
                <div class="hover-items">
                  <p class="user-title">
                    USER <a class="change-password" @click="changePassword()" v-if="userInfo.identity === 'platform'">change password</a>
                  </p>
                  <p class="short-name">{{ userInfo.name }}</p>

                  <p class="user-title">ACCOUNT</p>
                  <div class="tenant-name">
                    <p class="short-name">{{ userInfo.selectedTenant.displayName }}</p>
                    <i class="dropdown-toggle" :class="{ opened: tenantSelector }" v-if="userInfo.hasMasterTenant" @click="toggleTenantSelector"></i>
                  </div>
                  <ul class="tenant-selector" v-show="tenantSelector">
                    <li
                      :key="index"
                      v-for="(tenant, index) in userInfo.visibleTenants"
                      :class="{ selected: tenant.id === userInfo.selectedTenantId }"
                      :content="tenant.displayName"
                      @click="changeSelectedTenantId(tenant.id)"
                    >
                      {{ tenant.displayName }}
                    </li>
                  </ul>

                  <p class="user-title">SUBSCRIPTION</p>
                  <p class="short-name">{{ userInfo.selectedTenant.planName }}</p>
                  <p class="user-title">EMAIL</p>
                  <p class="short-name short-name-lowercase">{{ userInfo.id.toLowerCase() }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="items logout">
            <router-link :to="'/logout'">
              <i class="icon icon-logout-white"></i>
            </router-link>
          </div>
          <div class="items placeholder"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'

import Avatar from 'avatar-initials'
import { ClickOutside } from '../directives/click-outside'
import authService from '../../services/auth.service'
import usersService from '../../services/users.service'
import Select from '../helpers/Select.vue'
import EventBus from '../../services/EventBus'
import popupService from '../../services/popup.service'

@Component({
  directives: {
    ClickOutside
  },
  components: { Select }
})
export default class MainNav extends Vue {
  userInfo: any = {}
  upHere = false
  hire = false
  isUserAuthenticated = false
  tenantSelector = false
  isTenantChanging = false

  @Watch('$route')
  async onRouteChanged(to, _) {
    this.isUserAuthenticated = to.name == 'LogOut' ? false : await authService.isAuthenticated()
  }

  @Watch('isUserAuthenticated')
  async onUserAuthChanged() {
    if (this.isUserAuthenticated) {
      this.loadUser()
    } else {
      this.userInfo = {}
    }
  }

  async loadUser() {
    this.userInfo = await usersService.getUserInfo()
    this.userInfo.identity = authService.user?.identity
    if (!this.userInfo.name) {
      this.userInfo.name = authService.user?.profile?.name || ''
    }

    let initials = this.userInfo.name.match(/\b\w/g) || []
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    new Avatar(document.getElementById('avatar') as HTMLImageElement, {
      useGravatar: true,
      initials: initials,
      initial_fg: '#8743FF',
      initial_bg: '#fff',
      initial_font_family: "'Raleway', 'Lato-Regular', 'Helvetica Neue'",
      initial_weight: 400,
      email: this.userInfo.id
    })

    if (this.userInfo.isMasterTenantAdmin) {
      EventBus.addEventListener('tenant-created', ({ tenant }) => {
        this.userInfo.visibleTenants.push(tenant)
      })
      EventBus.addEventListener('tenant-deleted', ({ id }) => {
        this.userInfo.visibleTenants.splice(
          this.userInfo.visibleTenants.findIndex(t => t.id === id),
          1
        )
        if (id === this.userInfo.selectedTenantId) {
          this.changeSelectedTenantId(this.userInfo.visibleTenants[0].id)
        }
      })
    }
  }

  async changePassword() {
    await usersService.changePassword()
  }

  toggleHire() {
    this.hire = !this.hire
  }

  toggleTenantSelector(val?: boolean) {
    const newVal = typeof val === 'boolean' ? val : !this.tenantSelector
    this.tenantSelector = newVal
  }

  async getMatched() {
    this.toggleHire()
    try {
      await usersService.sendGetMatchEmail(this.userInfo.id)
      popupService.showInfo('Email successfully sent', `Thank you. We'll contact you as soon as possible to discuss your requirements`)
    } catch (error) {
      popupService.showError('Email error', 'Please contact support@blits.ai')
    }
  }

  async changeSelectedTenantId(id: string) {
    try {
      if (this.userInfo.selectedTenantId === id) {
        return
      }
      this.tenantSelector = false
      this.isTenantChanging = true
      await usersService.changeSelectedTenant(id)
      this.userInfo.selectedTenantId = id
      window.location.reload()
    } finally {
      this.isTenantChanging = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
@import '../../assets/scss/layout/mainnav';

.tenant-id-select {
  margin-bottom: 20px;
}

#hire,
#profile {
  @media (max-height: 899px) {
    display: none;
  }
}

.hire {
  background: transparent;
  border: none;
  padding: 0px;
  font-size: 16px;
}

.hover-box-hire {
  position: absolute;
  min-width: 650px;
  margin-top: -60px;
  left: 75px;
  min-height: 100px;
  background-color: $white;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 10px #0000001a;
  -ms-box-shadow: 0px 0px 10px #0000001a;
  box-shadow: 0px 0px 10px #0000001a;

  @media (max-height: 930px) {
    margin-top: -200px;

    &:before {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: -12px;
    top: 2px;
    border-style: solid;
    border-width: 20px 20px 20px 0;
    border-color: transparent $white transparent transparent;
  }

  .hire-button {
    margin: 10px 20px 20px 20px;
  }

  .hover-items {
    padding: 20px;

    .header {
      font-size: 24px;
      font-weight: bold;
      color: $default-dark;
    }

    .subheader {
      font-size: 16px;
      max-width: 540px;

      a {
        color: $main !important;
        font-weight: bold;
        font-size: 16px !important;
      }
    }

    .hire-image {
      position: absolute;
      right: 60px;
      top: 120px;
      max-height: 180px;
    }

    .container-hire {
      margin-top: 15px;
      max-width: 350px;

      i {
        float: left;
        margin-right: 5px;
      }

      .text {
        font-size: 16px;
      }
    }
  }
}

.change-password {
  color: $grey;
  font-size: 10px;
  cursor: pointer;
  margin-left: 20px;
  margin-bottom: 5px;
}

.change-password:hover {
  text-decoration: underline;
}
</style>
