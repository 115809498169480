<template>
  <div>
    <div class="custom-buttons">
      <div v-if="state.isInternal" class="analytics-button">
        <Toggle
          v-show="disableInteractions !== null"
          :disabled="isInteractionsToggleUpdating"
          label="Disable analytics"
          v-model="disableInteractions"
          @input="toggleDisableInteractions"
        ></Toggle>
      </div>

      <button
        v-if="state.isInternal"
        type="button"
        id="publicTestChatButton"
        class="toggle-details"
        data-toggle="dropdown"
      >Share testchat</button>

      <div
        v-if="state.isInternal && hasSufficientPermission"
        class="dropdown-menu test"
        aria-labelledby="publicTestChatButton"
      >
        <a
          id="external-chat-link"
          class="public-testchat-button"
          href="/test-chat"
          target="_blank"
        >Open testlink</a>
        <a
          class="public-testchat-button"
          @click="openTestableChatPassword"
        >Show password</a>
        <a
          id="external-chat-pass"
          class="public-testchat-button"
          @click="refreshTestableChatPassword"
        >Reset password</a>
      </div>

      <button
        class="toggle-details"
        @click="state.areDetailsOpened = !state.areDetailsOpened"
      >{{ state.areDetailsOpened ? 'Hide details' : 'Show details' }}</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import chatService from '../../../../services/bots/chat.service'
import { GeneralSettingsService } from '../../../../services/bots/settings/general-settings.service'
import popupService from '../../../../services/popup.service'
import permissionsService from '../../../../services/tenants/permissions.service'
import Toggle from '../../../helpers/Toggle.vue'
import { SharedState } from './shared-state'

@Component({ components: { Toggle } })
export default class extends Vue {
  @Prop() state: SharedState;

  settingsService: GeneralSettingsService;
  hasSufficientPermission = false;
  disableInteractions = null;
  isInteractionsToggleUpdating = false;

  async created () {
    if (this.state.isInternal) {
      this.settingsService = new GeneralSettingsService(this.state.botName)
      this.settingsService.getSettings()
        .then(res => this.disableInteractions = res.settings.disableTestInteractions)
      this.hasSufficientPermission = await permissionsService.hasPermission(
        this.state.botName,
        Permissions.ManageTestChatLink
      )
    }
  }

  async toggleDisableInteractions () {
    try {
      this.isInteractionsToggleUpdating = true
      const { settings } = await this.settingsService.getSettings()
      settings.disableTestInteractions = this.disableInteractions
      await this.settingsService.updateSettings(undefined, settings, undefined)
    } finally {
      this.isInteractionsToggleUpdating = false
    }
  }

  async openTestableChatPassword () {
    const password = await chatService.getExtChatPassword(this.state.botName)
    popupService.showInfo('Password', password)
  }

  async refreshTestableChatPassword () {
    const password = await chatService.refreshExtChatPassword(this.state.botName)
    popupService.showInfo('New Password', password)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/components/_chat.scss";

.custom-buttons {
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  font-family: Raleway, sans-serif;

  transition: opacity 1s ease-out;

  a, button {
    text-align: center;
    min-width: 130px;
    max-width: 130px;
  }

  .analytics-button {
    margin-top: 20px;
    margin-right: 20px;

    i {
      transition: none !important;
    }

    p {
      color: $white !important;
      font-size: 12px !important;

      padding-top: 3.5px !important;
      margin-left: 10px !important;
    }
  }

  #external-chat-link {
    color: inherit;
    text-decoration: none;
  }

  .toggle-details {
    background: rgba($sent-text, 0.2) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #0000001A;
    border-radius: 20px;
    height: 35px;
    border: 0;
    color: $sent-text;
    margin: auto 15px;
    font-size: 12px;
    padding: 10px 20px;
    outline: none;
  }

  .dropdown-menu {
    &.test {
      top: 8px;
      left: 80px;
      -webkit-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
      -ms-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
      box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
      border: none;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: -6px;
        left: 5px;
        width: 8px;
        height: 6px;
        border-left: solid 6px transparent;
        border-right: solid 6px transparent;
      }
      &:before {
        border-bottom: solid 6px $white;
      }
      &:after {
        top: -4px;
        border-bottom: solid 6px $white;
      }
    }
  }

  .public-testchat-button {
    cursor: pointer;
    font-size: 14px;
    color: $default-dark;
    text-align: left;
    background: transparent;
    border: none;
    display: block;
    margin: 0px 0px 0px 0px;
    padding: 3px 0px 3px 20px;
    min-width: 0px;
    max-width: unset;

    &:hover {
      border-left: 4px solid $main;
      padding-left: 16px;
      color: $main;
      font-weight: bold;
      background-color: #f8f9fa;
    }
  }
}
</style>
